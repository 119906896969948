.card {
  width: 100%;
  padding-left: 15%;
    padding-right: 15%;
  height: 80%;
  margin: auto;
  min-height: 512px;
  box-shadow: 0px 35px 70px 0px rgba(189, 168, 158, 0.65), 0px 30px 40px -10px rgba(255, 100, 124, 0.55);
  background: linear-gradient(180deg, rgb(216, 171, 202) 20%, rgb(188, 72, 179) 100%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.gyro-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #333; /* ou une autre couleur qui correspond à votre design */
  }

.tilt-wrapper {
    width: 100%;
    height: 80%; /* ou la hauteur désirée */
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  @media screen and (max-width: 512px) {
    .card {
      width: calc(100% - 20px); /* ajuste la largeur pour laisser de la place aux marges */
      margin: 10px; /* ajoute une marge de 10px de tous les côtés */
      border-radius: 10px; /* maintient les bordures arrondies */
    }
  }
  

.header {
  display: flex;
  padding: 48px;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

.logo {
  font-weight: bold;
  font-size: 1.5em;
  transition: opacity 0.05s ease;
}

.logo:hover {
  opacity: 0.75;
}

.social {
  display: flex;
}

.social a {
  display: inline-block;
  margin-right: 12px;
  transition: opacity 0.05s ease;
}

.social a:last-child {
  margin-right: 0;
}

.social a:hover {
  opacity: 0.75;
}

.social .icon {
  width: 18px;
  fill: #fff;
}

.content {
  flex: 1 1 auto;
  min-height: 256px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content .title-holder {
  color: #fff;
  text-align: center;
  margin-bottom: 24px;
}

.content .title-holder h1 {
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 12px;
}

.content .title-holder p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.75);
}

@media screen and (max-width: 768px) {
  .content .title-holder {
    max-width: 80%;
  }
}

.content .cta {
  min-width: 64px;
  padding: 16px 24px;
  background: #fff;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  transform: none;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.25, 0.315, 1.35), transform 0.1s linear;
}

.content .cta:hover {
  transform: translateY(-1px);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.3);
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 48px;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

a.underlined:hover {
  border-bottom: 1px dotted #fff;
}
